<template>
  <div class="open-charge">
    <div class="df-row">
      <div class="df-col date-picker">
        <el-date-picker
          v-model="multipleDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <div class="df-col">
        <el-select
          v-model="submitData.KeepType"
          placeholder="账单类型"
          size="mini"
          clearable
        >
          <el-option
            v-for="item in keepTypeSelector"
            :key="'keep' + item.Value"
            :label="item.Name"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <el-select
          v-model="submitData.PaymentGuid"
          placeholder="付款方式"
          size="mini"
          clearable
        >
          <el-option
            v-for="item in paymentSelector"
            :key="item.PaymentGuid"
            :label="item.PaymentName"
            :value="item.PaymentGuid"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <el-select
          v-model="submitData.EmplGuid"
          placeholder="提成员工"
          size="mini"
          clearable
        >
          <el-option
            v-for="item in employeeSelector"
            :key="item.EmplGuid"
            :label="item.EmplName"
            :value="item.EmplGuid"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <el-select
          v-model="submitData.KeepAdmin"
          placeholder="操作人"
          size="mini"
          clearable
        >
          <el-option
            v-for="item in managerSelector"
            :key="item.ManagerGuid"
            :label="item.ManagerName"
            :value="item.ManagerGuid"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <el-input
          v-model="submitData.Word"
          placeholder="请输入单号/姓名/手机号/卡号"
          size="mini"
          clearable
        ></el-input>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch">查询</el-button>
      </div>

      <div class="df-col">
        <DfExportButton
          ref="dfExportButton"
          :fileName="fileName"
          :checkAllow="false"
          @export="onExportExcel"
        ></DfExportButton>
      </div>
    </div>

    <!-- start 项目分类统计明细表 -->
    <el-table
      class="items-table"
      v-loading="loading"
      :data="billList"
      :max-height="tableMaxHeight"
      :span-method="objectSpanMethod"
      stripe
      border
    >
      <el-table-column
        prop="BillNo"
        label="单号"
        align="center"
        :width="adaptiveDefault.tableColumnWidth.billNo"
      ></el-table-column>
      <el-table-column
        prop=""
        label="充值时间"
        align="center"
        :width="adaptiveDefault.tableColumnWidth.investTime"
      >
        <template slot-scope="scope">
          {{
            scope.row.KeepTime
              ? util.dateFormat(
                  "YYYY-mm-dd HH:MM:SS",
                  new Date(scope.row.KeepTime * 1000)
                )
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        label="客户"
        align="center"
        :width="adaptiveDefault.tableColumnWidth.memberInfo"
      >
        <template slot-scope="scope">
          <div class="member-box">
            <span>{{ scope.row.MemberName }}</span>
            <span>{{ scope.row.MemberPhone }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        label="金额"
        align="center"
        :width="adaptiveDefault.tableColumnWidth.amountInfo"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.BillAmount" class="amount-box">
            <span>总金额：{{ scope.row.BillAmount }}</span>
            <span class="line"></span>
            <div
              class="payment-info"
              v-for="(item, index) in scope.row.PaymentInfoList"
              :key="item.PaymentGuid"
            >
              <span
                class="payment-money color"
                :class="item.PaymentType == 1 ? 'active' : ''"
                >{{ item.PayMoney }}</span
              >
              <span :class="item.PaymentType == 1 ? 'active' : ''">{{
                item.PaymentName
              }}</span>
            </div>
            <div
              class="vip-items-info"
              v-for="(item, index) in scope.row.VipItemCardList"
              :key="item.VipGuid"
            >
              <span>{{ item.CardName }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="KeepTypeText"
        label="充值类型"
        align="center"
        :width="adaptiveDefault.tableColumnWidth.investType"
      ></el-table-column>
      <el-table-column
        prop=""
        label="内容"
        align="center"
        :width="adaptiveDefault.tableColumnWidth.itemInfo"
      >
        <template slot-scope="scope">
          <div class="detali-box">
            <span>{{ scope.row.CardName }}</span>
            <span>{{ scope.row.VipNo ? "No." + scope.row.VipNo : "" }}</span>
            <span>{{ scope.row.NatureName }}</span>
            <span v-show="scope.row.Detail" class="line"></span>
            <span>{{ scope.row.Detail }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="员工"
        align="center"
        :width="adaptiveDefault.tableColumnWidth.emplInfo"
      >
        <template slot-scope="scope">
          <div class="items-box">
            <el-table
              v-if="scope.row.EmplInfoList.length > 0"
              :data="scope.row.EmplInfoList"
              :show-header="false"
              :border="false"
              class="custom-table"
              v-loading="loading"
            >
              <el-table-column
                align="center"
                :width="adaptiveDefault.tableColumnWidth.emplInfo"
              >
                <template slot-scope="scope">
                  {{
                    `${scope.row.EmplNo}_${scope.row.LevelName}_${scope.row.EmplName}`
                  }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                :width="adaptiveDefault.tableColumnWidth.keepRoya"
              >
                <template slot-scope="scope">
                  {{ scope.row.Roya }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                :width="adaptiveDefault.tableColumnWidth.keepPerfor"
              >
                <template slot-scope="scope">
                  {{ scope.row.Perfor }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="提成"
        align="center"
        :width="adaptiveDefault.tableColumnWidth.keepRoya"
      ></el-table-column>
      <el-table-column
        label="业绩"
        align="center"
        :width="adaptiveDefault.tableColumnWidth.keepPerfor"
      ></el-table-column>
      <el-table-column
        prop="OperatorName"
        label="操作人"
        align="center"
        :width="adaptiveDefault.tableColumnWidth.operator"
      ></el-table-column>
    </el-table>
    <!-- end 项目分类统计明细表 -->

    <div v-if="billList.length > 0" class="footer-box">
      <div class="vip-total">
        充值合计：{
        <span v-for="(item, index) in totalStatis.BillStatisItems" :key="index"
          >{{ item.Name }}：{{ item.Amount }}元{{
            index == totalStatis.BillStatisItems.length - 1 ? "" : "，"
          }}</span
        >
        }
      </div>
      <div class="pay-total">
        结算统计：{
        <span v-for="(item, index) in totalStatis.PayStatisItems" :key="index"
          >{{ item.Name }}：{{ item.Amount }}元{{
            index == totalStatis.PayStatisItems.length - 1 ? "" : "，"
          }}</span
        >
        }
      </div>
    </div>

    <!-- start 分页器 -->
    <el-pagination
      background
      :page-sizes="[10, 20, 30, 40]"
      :page-size="submitData.PageSize"
      :current-page="submitData.PageIndex"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!--end 分页器-->
  </div>
</template>

<script>
import Report from "@/api/report.js";

export default {
  data() {
    return {
      tableMaxHeight: "",
      fileName: "",
      totalRecord: 0,
      pageIndex: 1,
      pageSize: 10,
      multipleDate: [],
      submitData: {
        BeginDate: "",
        EndDate: "",
        Word: "",
        KeepType: 0,
        PaymentGuid: "",
        NatureNo: "",
        KeepAdmin: "",
        EmplGuid: "",
        PageIndex: 1,
        PageSize: 10,
      },
      totalStatis: {
        PayStatisItems: [],
        BillStatisItems: [],
      },
      billList: [],
      employeeSelector: [],
      keepTypeSelector: [],
      managerSelector: [],
      natureSelector: [],
      paymentSelector: [],
      tableList: [],
      loading: false,
      isCheckEmpl: false,

      mediaWidthAdaptive: {
        "1000": {
          tableColumnWidth: {
            billNo: "100",
            investTime: "80",
            memberInfo: "80",
            amountInfo: "90",
            investType: "60",
            itemInfo: "",
            emplInfo: "100",
            keepRoya: "40",
            keepPerfor: "40",
            operator: "50",
          },
        },
        "1280": {
          tableColumnWidth: {
            billNo: "100",
            investTime: "",
            memberInfo: "",
            amountInfo: "",
            investType: "70",
            itemInfo: "",
            emplInfo: "180",
            keepRoya: "60",
            keepPerfor: "60",
            operator: "60",
          },
        },
        "1920": {
          tableColumnWidth: {
            billNo: "140",
            investTime: "",
            memberInfo: "",
            amountInfo: "",
            investType: "100",
            itemInfo: "280",
            emplInfo: "180",
            keepRoya: "100",
            keepPerfor: "100",
            operator: "120",
          },
        },
      },
      adaptiveDefault: {
        tableColumnWidth: {
          billNo: "",
          investTime: "",
          memberInfo: "",
          amountInfo: "",
          investType: "",
          itemInfo: "",
          emplInfo: "",
          keepRoya: "",
          keepPerfor: "",
          operator: "",
        },
      },
    };
  },
  created() {
    this.getResize();
  },

  async mounted() {
    window.addEventListener("resize", this.getResize);

    await this.initDateState();
    await this.initOpenKeepBillRecord();
    await this.queryOpenKeepBillRecord();
    // await this.onPaging();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight, clientWidth } = this.util.getDocumentElementInfo();
      let tableMaxHeight = clientHeight - (100 + 40 + 68 + 80);

      this.tableMaxHeight = tableMaxHeight > 400 ? tableMaxHeight : 400;
      for (let key in this.mediaWidthAdaptive) {
        // console.log(Number(key));
        if (clientWidth <= Number(key)) {
          this.adaptiveDefault = {};
          this.adaptiveDefault = JSON.parse(
            JSON.stringify(this.mediaWidthAdaptive[key])
          );
          break;
        }
      }
    },

    // 初始化开充卡
    async initOpenKeepBillRecord() {
      try {
        let { data } = await Report.initOpenKeepBillRecord();

        data.employeeSelector.unshift({
          EmplGuid: "",
          EmplName: "全部员工",
          LevelGuid: "",
        });
        data.keepTypeSelector.unshift({ Name: "全部账单", Value: 0 });
        data.managerSelector.unshift({
          ManagerGuid: "",
          ManagerName: "全部操作人",
        });
        data.natureSelector.unshift({ NatureName: "全部类型", NatureNo: "" });
        data.paymentSelector.unshift({
          PaymentGuid: "",
          PaymentName: "全部付款",
        });

        this.employeeSelector = data.employeeSelector;
        this.keepTypeSelector = data.keepTypeSelector;
        this.managerSelector = data.managerSelector;
        this.natureSelector = data.natureSelector;
        this.paymentSelector = data.paymentSelector;
      } catch (err) {
        console.log(err);
      }
    },

    // 查询开充卡
    async queryOpenKeepBillRecord(isExport = 0) {
      this.loading = true;
      try {
        let { data } = await Report.queryOpenKeepBillRecord(
          this.submitData,
          isExport
        );
        if (isExport) {
          return (this.fileName = data);
        } else if (this.submitData.PageIndex == 1) {
          this.totalStatis = data.statis || [];
        }
        this.billList = data.list;
        this.totalRecord = data.filter.TotalRecord;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 假分页
    onPaging() {
      let billList = JSON.parse(JSON.stringify(this.billList));
      let pageSize = this.pageSize;
      let pageIndex = this.pageIndex;
      let start = (pageIndex - 1) * pageSize;
      let end = pageIndex * pageSize;
      let list = billList.slice(start, end);
      this.tableList = list;
    },

    // 查询按钮点击事件
    async onSearch() {
      this.submitData.PageIndex = 1;
      await this.queryOpenKeepBillRecord();
      // await this.onPaging();
    },

    onExportExcel() {
      this.queryOpenKeepBillRecord(1);
    },

    // 初始化日期状态
    async initDateState() {
      let { beginDay, endDay } = await this.$store.dispatch("onGetDateState");
      this.multipleDate = [beginDay, endDay];
      this.submitData.BeginDate = beginDay;
      this.submitData.EndDate = endDay;
    },

    // 日期选择器改变时间
    onChangeDate(event) {
      this.$store.dispatch("onChangeDateState", { dayTime: event });
      this.initDateState();
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      //return this.twoDimensionalArray(rowIndex, columnIndex);

      if (columnIndex == 6) {
        return {
          rowspan: 1, //rowIndex % 2 == 0 ? 2 : 0,
          colspan: 3, //rowIndex % 2 == 0 ? 5 : 0
        };
      } else if (columnIndex > 6 && columnIndex < 9) {
        return {
          rowspan: 0,
          colspan: 0,
        };
      }
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.PageIndex = 1;
      this.submitData.PageSize = event;
      this.queryOpenKeepBillRecord();
      // this.onPaging();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.PageIndex = event;
      this.queryOpenKeepBillRecord();
      // this.onPaging();
    },
  },
};
</script>

<style lang="less" scoped>
.open-charge {
  .df-row {
    margin-top: 20px;

    .df-col {
      .el-input {
        width: 200px;
      }

      .el-select {
        width: 140px;
      }
    }
  }

  .items-table {
    ::v-deep .cell {
      padding: 0;
    }

    ::v-deep tbody > tr {
      td:nth-child(7) {
        padding: 0;
        > .cell {
          min-height: 60px;
        }
      }
    }

    > .el-table__body-wrapper {
      > table > tbody > tr {
        td {
          padding: 0;
        }
      }
    }

    ::v-deep tr {
      &:last-child {
        td:last-child {
          border-right: 0;
        }
      }
      td:last-child {
        // border-bottom: 0;
        border-right: 0;
      }
    }

    ::v-deep .custom-table {
      height: 100%;
      min-height: 60px;
      padding: 0;
      border-right: 0 !important;
      border-bottom: 0 !important;

      &,
      & * {
        overflow: hidden;
      }

      &::before {
        display: none;
      }

      table,
      .el-table__body-wrapper {
        height: 100%;
        min-height: 60px;
      }
      > .el-table__body-wrapper {
        > table > tbody > tr {
          &:last-child {
            > td {
              border-bottom: 0;
            }
            > td:last-child {
              border-right: 0;
              .three-table {
                border-bottom: 0 !important;
              }
            }
          }
          td {
            padding: auto 0;
          }
        }
      }

      .cell {
        height: 100%;

        > div {
          .flex-col;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }

      .second-level
        > .el-table
        > .el-table__body-wrapper
        > table
        > tbody　
        > tr:last-child
        > td {
        border-bottom: 0;
      }

      .second-level
        > .el-table
        > .el-table__body-wrapper
        > table
        > tbody　
        > tr
        > td:last-child,
      .three-level
        > .el-table
        > .el-table__body-wrapper
        > table
        > tbody　
        > tr
        > td:last-child {
        border-right: 0;
      }
    }

    ::v-deep .three-table {
      height: 100%;
      border-right: 0 !important;

      &::before {
        display: none;
      }

      table,
      .el-table__body-wrapper {
        height: 100%;
        min-height: 60px;
      }
      > .el-table__body-wrapper {
        > table > tbody > tr {
          &:last-child {
            > td {
              border-bottom: 0;
            }
            > td:last-child {
              border-right: 0;
              .three-table {
                border-bottom: 0 !important;
              }
            }
          }
          td {
            padding: 0;
          }
          > td:last-child {
            // border-bottom: 0;
          }
        }
      }

      table,
      .el-table__body-wrapper {
        height: 100%;
      }
      .cell {
        .flex-col;
        justify-content: center;
        height: 100%;

        > div {
          .flex-col;
          justify-content: center;
          height: 100%;

          .empl-no {
            margin-right: 10px;
          }
        }
      }
    }

    .member-box,
    .amount-box,
    .detali-box {
      .flex-col;
    }

    .line {
      width: 100%;
      border-bottom: 1px dashed #ccc;
    }
  }

  .footer-box {
    .flex-col;
    align-items: unset;
    margin-block-start: 5px;

    .flex-col;
    align-items: unset;

    .vip-total {
      color: @warning-color;
    }
    .pay-total {
      color: @succes-color;
    }
  }

  .el-pagination {
    margin-top: 20px;
    float: right;
  }

  @media (max-width: 1280px) {
    .df-row {
      .flex-row;
      align-items: unset;
      flex-wrap: wrap;
      .df-col {
        .el-date-editor {
          width: 220px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
